/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, Menu, ColumnWrapper, Image, Title, MenuWrap, MenuButton, MenuWrapBox, MenuButtonClose, Button, ColumnWrap, Text, SeparateLine, SeparateLineWrap, PriceWrap, ColumnCover } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Jazdecký klub I-Horses"}>
        <Column className="--menu css-e2f8zg --style3 bg--center pb--08 pt--08" menu={true} name={"mxofbf1po0b"} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/17777/8ef0c71b71f24c4897d7f779f15d1928_s=350x_.JPG);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/17777/8ef0c71b71f24c4897d7f779f15d1928_s=660x_.JPG);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/17777/8ef0c71b71f24c4897d7f779f15d1928_s=860x_.JPG);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/17777/8ef0c71b71f24c4897d7f779f15d1928_s=1400x_.JPG);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/17777/8ef0c71b71f24c4897d7f779f15d1928_s=2000x_.JPG);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/17777/8ef0c71b71f24c4897d7f779f15d1928_s=660x_.JPG);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/17777/8ef0c71b71f24c4897d7f779f15d1928_s=1400x_.JPG);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/17777/8ef0c71b71f24c4897d7f779f15d1928_s=3000x_.JPG);
    }
  
    `}>
          
          <Menu className="flex--center" style={{"maxWidth":879}}>
            
            <ColumnWrapper className="menu-logo-box --justify" style={{"maxWidth":380}}>
              
              <Image className="--justify" alt={"Logo"} src={"https://cdn.swbpg.com/t/17777/8f5081f7525743eab9990f80019da61c_s=860x_.gif"} svg={false} sizes="(max-width: 1439px) 100vw, 1440px" style={{"maxWidth":158,"backgroundColor":"rgba(255,255,255,1)"}} srcSet={"https://cdn.swbpg.com/t/17777/8f5081f7525743eab9990f80019da61c_s=350x_.gif 350w, https://cdn.swbpg.com/t/17777/8f5081f7525743eab9990f80019da61c_s=660x_.gif 660w, https://cdn.swbpg.com/t/17777/8f5081f7525743eab9990f80019da61c_s=860x_.gif 860w, https://cdn.swbpg.com/t/17777/8f5081f7525743eab9990f80019da61c_s=1400x_.gif 1400w"} RootClassName={"column__pic"}>
              </Image>

              <Title className="title-box title-box--center ff--2" style={{"maxWidth":759}} content={"<span style=\"color: rgb(255, 255, 255);\">I- Horses</span><br>"}>
              </Title>

            </ColumnWrapper>

            <MenuWrap >
              
              <MenuButton >
              </MenuButton>

              <MenuWrapBox className="--justify mt--80 pb--0 pr--08 pt--80">
                
                <MenuButtonClose >
                </MenuButtonClose>

                <Button className="btn-box btn-box--cbtn2 btn-box--sbtn2" href={"/"} target={""} content={"<span style=\"color: rgb(255, 255, 255);\">I-Agro</span>"}>
                </Button>

                <Button className="btn-box btn-box--cbtn2 btn-box--sbtn2" href={"#bi4wbprej98"} target={""} content={"<span style=\"color: rgb(255, 255, 255);\">Úvod</span>"}>
                </Button>

                <Button className="btn-box btn-box--cbtn2 btn-box--sbtn2" href={"#6rgs38gltzf"} style={{"maxWidth":98}} target={""} content={"<span style=\"color: rgb(255, 255, 255);\">Galéria</span>"}>
                </Button>

                <Button className="btn-box btn-box--cbtn2 btn-box--sbtn2" href={"#frqcl30kg56"} target={""} content={"<span style=\"color: rgb(255, 255, 255);\">Kontakt</span>"}>
                </Button>

                <Button className="btn-box btn-box--hvr3 btn-box--cbtn2 btn-box--sbtn2 btn-box--tsbtn2 btn-box--cColor2" href={"#vftpaz91n4s"} tsbtn={"2"} target={""} content={"<span style=\"color: rgb(255, 255, 255);\">Cenník</span>"}>
                </Button>

              </MenuWrapBox>

            </MenuWrap>

          </Menu>

        </Column>


        <Column className="pb--60 pt--60" name={"qunz5rp3bg"} layout={"l9"}>
          
          <ColumnWrap className="column__flex --center el--1 flex--bottom" columns={"1"}>
            
            <ColumnWrapper className="--left" style={{"maxWidth":""}}>
              
              <Text className="text-box text-box--left pl--0" style={{"maxWidth":562}} content={"<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"bi4wbprej98"}>
          
          <ColumnWrap className="column__flex --left el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17777/68ccea0d78014f1181ca370e4a2b2b3b_s=860x_.JPG"} svg={false} ratio={"4:3"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":354}} srcSet={"https://cdn.swbpg.com/t/17777/68ccea0d78014f1181ca370e4a2b2b3b_s=350x_.JPG 350w, https://cdn.swbpg.com/t/17777/68ccea0d78014f1181ca370e4a2b2b3b_s=660x_.JPG 660w, https://cdn.swbpg.com/t/17777/68ccea0d78014f1181ca370e4a2b2b3b_s=860x_.JPG 860w, https://cdn.swbpg.com/t/17777/68ccea0d78014f1181ca370e4a2b2b3b_s=1400x_.JPG 1400w, https://cdn.swbpg.com/t/17777/68ccea0d78014f1181ca370e4a2b2b3b_s=2000x_.JPG 2000w"} position={{"x":"-17.88622754491017%","y":"-15.400000000000004%"}}>
              </Image>

              <Title className="title-box" content={"Kurzy"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":467}} content={"Pre deti, dospelých, individuálne hodiny<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17777/9d54afbee2b04e4b89474b8d669662e6_s=860x_.jpg"} svg={false} ratio={"4:3"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":655}} srcSet={"https://cdn.swbpg.com/t/17777/9d54afbee2b04e4b89474b8d669662e6_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17777/9d54afbee2b04e4b89474b8d669662e6_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17777/9d54afbee2b04e4b89474b8d669662e6_s=860x_.jpg 860w, https://cdn.swbpg.com/t/17777/9d54afbee2b04e4b89474b8d669662e6_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/17777/9d54afbee2b04e4b89474b8d669662e6_s=2000x_.jpg 2000w"} position={null}>
              </Image>

              <Title className="title-box" content={"Ustajnenie<br>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":467}} content={"Priestranné výbehy pre kone, boxy 3x4m, jazdiareň<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17777/3585782028cd4da58aa08cba2dfe79d7_s=860x_.jpg"} svg={false} ratio={"4:3"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":655}} srcSet={"https://cdn.swbpg.com/t/17777/3585782028cd4da58aa08cba2dfe79d7_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17777/3585782028cd4da58aa08cba2dfe79d7_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17777/3585782028cd4da58aa08cba2dfe79d7_s=860x_.jpg 860w, https://cdn.swbpg.com/t/17777/3585782028cd4da58aa08cba2dfe79d7_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/17777/3585782028cd4da58aa08cba2dfe79d7_s=2000x_.jpg 2000w"} position={null}>
              </Image>

              <Title className="title-box title-box--center" content={"Výcvik koní<br>"}>
              </Title>

              <Text className="text-box text-box--center" style={{"maxWidth":467}} content={"Individuálne&nbsp;<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"igyi1t1xh4"} layout={"l6"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="pb--60 pt--60" name={"dhpmaepbxv"}>
          
          <ColumnWrap className="column__flex el--1" columns={"1"}>
            
            <ColumnWrapper className="--justify" style={{"maxWidth":917}}>
              
              <Title className="title-box title-box--right" content={"Cenník kurzov a služieb<br>"}>
              </Title>

              <Text className="text-box" content={"<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"vftpaz91n4s"}>
          
          <ColumnWrap className="column__flex el--2" columns={"2"}>
            
            <ColumnWrapper >
              
              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"<span style=\"color: rgb(208, 2, 27);\">Klubový jazdci</span><br>"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"Permanentka 1krát týždenne/ mesiac&nbsp;&nbsp;"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"50€"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"Permanentka 2 krát týždenne/ mesiac&nbsp;"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"70€"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"Klubový členský poplatok na sezónu&nbsp;"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"19€"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"<span style=\"color: rgb(208, 2, 27);\">Služby&nbsp;</span><br>"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"Opohybovanie koní na lonži v neprítomnosti majiteľa&nbsp;"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"5€/hod"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"Asistencia veterinárovi, kováčovi&nbsp;"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"5€/kus&nbsp;"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"Poplatky so SJF podľa aktuálneho cenníka SJF"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={""}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"Ustajnenie<br>"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"Ustajnenie box 3x4m"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"130€"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"Ustajnenie box 3x3m,&nbsp;&nbsp;"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"130€"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex el--2" style={{"maxWidth":""}} columns={"2"}>
            
            <ColumnWrapper >
              
              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"<span style=\"color: rgb(208, 2, 27);\">Nečlenovia jazdenie&nbsp;</span><br>"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"Jazdecká hodina&nbsp; &nbsp;&nbsp;"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"20€"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"Vychádzka do terénu (len pre pokročilých)"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"30€/hod"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"Krokovanie detičky, dospelý, handikepovaný&nbsp;&nbsp;"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"1€/kolo&nbsp;"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"<span style=\"color: rgb(208, 2, 27);\">Služby&nbsp;</span><br>"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"Očkovanie chrípka+ tetanus+ herpes vírus"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"25€"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"Korektúra&nbsp;"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"30€/kus +DPH&nbsp;"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"Odčervenie&nbsp;"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"21Eur&nbsp;&nbsp;"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"Preprava<br>"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"Preprava koní&nbsp;"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"0,70€/km"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"Stojné&nbsp;"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"8€/hod"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"lb01tazuq78"}>
          
          <ColumnWrap className="column__flex --left el--3" style={{"maxWidth":1358}} columns={"3"}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17777/ddc0a5c133b945bb99969fe41449ea85_s=660x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":655}} srcSet={"https://cdn.swbpg.com/t/17777/ddc0a5c133b945bb99969fe41449ea85_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17777/ddc0a5c133b945bb99969fe41449ea85_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17777/ddc0a5c133b945bb99969fe41449ea85_s=860x_.jpg 860w"} position={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17777/b792bf81a1d0481a8a9c2c49ba201cdd_s=350x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":655}} srcSet={"https://cdn.swbpg.com/t/17777/b792bf81a1d0481a8a9c2c49ba201cdd_s=350x_.jpg 350w"} position={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17777/cee38e52c8ed4dfab1b92c33d667fa85_s=660x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":450}} srcSet={"https://cdn.swbpg.com/t/17777/cee38e52c8ed4dfab1b92c33d667fa85_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17777/cee38e52c8ed4dfab1b92c33d667fa85_s=660x_.jpg 660w"} position={null}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"a6sjdjrnp98"} layout={"l8"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17777/9019de3475f94a7998f0e2b3d4bb0e18_s=660x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/17777/9019de3475f94a7998f0e2b3d4bb0e18_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17777/9019de3475f94a7998f0e2b3d4bb0e18_s=660x_.jpg 660w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17777/1485a81e2ae5443b94c028f8a51031be_s=860x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/17777/1485a81e2ae5443b94c028f8a51031be_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17777/1485a81e2ae5443b94c028f8a51031be_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17777/1485a81e2ae5443b94c028f8a51031be_s=860x_.jpg 860w, https://cdn.swbpg.com/t/17777/1485a81e2ae5443b94c028f8a51031be_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17777/8e3ebc58f2264d77a89787a58f2fe85b_s=350x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":265}} srcSet={"https://cdn.swbpg.com/t/17777/8e3ebc58f2264d77a89787a58f2fe85b_s=350x_.jpg 350w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17777/e9f5a8081eba431dbd9570112f4da655_s=660x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/17777/e9f5a8081eba431dbd9570112f4da655_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17777/e9f5a8081eba431dbd9570112f4da655_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17777/e9f5a8081eba431dbd9570112f4da655_s=860x_.jpg 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17777/b413cc72a2e149d8a8e566113b27ebd7_s=660x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/17777/b413cc72a2e149d8a8e566113b27ebd7_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17777/b413cc72a2e149d8a8e566113b27ebd7_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17777/b413cc72a2e149d8a8e566113b27ebd7_s=860x_.jpg 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17777/9362489fe4ca4fcfb78121b69f26c501_s=860x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":413}} srcSet={"https://cdn.swbpg.com/t/17777/9362489fe4ca4fcfb78121b69f26c501_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17777/9362489fe4ca4fcfb78121b69f26c501_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17777/9362489fe4ca4fcfb78121b69f26c501_s=860x_.jpg 860w, https://cdn.swbpg.com/t/17777/9362489fe4ca4fcfb78121b69f26c501_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/17777/9362489fe4ca4fcfb78121b69f26c501_s=2000x_.jpg 2000w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"3a98872iby9"} layout={"l6"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="pb--60 pt--60" name={"6rgs38gltzf"} layout={"l29"}>
          
          <ColumnWrap className="column__flex --center">
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17777/2399957fb34240a59cd6ace24c0705a1_s=860x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/17777/2399957fb34240a59cd6ace24c0705a1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17777/2399957fb34240a59cd6ace24c0705a1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17777/2399957fb34240a59cd6ace24c0705a1_s=860x_.jpg 860w, https://cdn.swbpg.com/t/17777/2399957fb34240a59cd6ace24c0705a1_s=1400x_.jpg 1400w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17777/a8720f4969cc478b973761d4595db6d4_s=860x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/17777/a8720f4969cc478b973761d4595db6d4_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17777/a8720f4969cc478b973761d4595db6d4_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17777/a8720f4969cc478b973761d4595db6d4_s=860x_.jpg 860w, https://cdn.swbpg.com/t/17777/a8720f4969cc478b973761d4595db6d4_s=1400x_.jpg 1400w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17777/d78cf77513874f29b3059cfc4706d337_s=860x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/17777/d78cf77513874f29b3059cfc4706d337_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17777/d78cf77513874f29b3059cfc4706d337_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17777/d78cf77513874f29b3059cfc4706d337_s=860x_.jpg 860w, https://cdn.swbpg.com/t/17777/d78cf77513874f29b3059cfc4706d337_s=1400x_.jpg 1400w"} RootClassName={"column__pic"}>
              </Image>

              <Image src={"https://cdn.swbpg.com/t/17777/0d8df4f914764186b31e01cc888665f5_s=660x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" style={{"maxWidth":360}} srcSet={"https://cdn.swbpg.com/t/17777/0d8df4f914764186b31e01cc888665f5_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17777/0d8df4f914764186b31e01cc888665f5_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17777/0d8df4f914764186b31e01cc888665f5_s=860x_.jpg 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17777/1c76df1b2e374775b3c5983b7231c7a3_s=660x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/17777/1c76df1b2e374775b3c5983b7231c7a3_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17777/1c76df1b2e374775b3c5983b7231c7a3_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17777/1c76df1b2e374775b3c5983b7231c7a3_s=860x_.jpg 860w"} position={{"x":"-14.339677891654466%","y":"-5.7299270072992705%"}} RootClassName={"column__pic"}>
              </Image>

              <Image src={"https://cdn.swbpg.com/t/17777/c5b1fc9ca222453fa6591ab612d4c96b_s=860x_.jpg"} svg={false} ratio={"3:2"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/17777/c5b1fc9ca222453fa6591ab612d4c96b_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17777/c5b1fc9ca222453fa6591ab612d4c96b_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17777/c5b1fc9ca222453fa6591ab612d4c96b_s=860x_.jpg 860w, https://cdn.swbpg.com/t/17777/c5b1fc9ca222453fa6591ab612d4c96b_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/17777/c5b1fc9ca222453fa6591ab612d4c96b_s=2000x_.jpg 2000w"} position={{"x":"-20.32064421669107%","y":"-53.357664233576635%"}} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"3a98872iby9"} layout={"l6"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <ColumnCover name={"3inlpptbzg2"}>
          
          <Column className="--menu --style4 pb--08 pt--08" menu={true} lightbox={false}>
          </Column>

        </ColumnCover>


        <ColumnCover name={"50xl78uotcs"}>
        </ColumnCover>


        <Column className="pb--80 pt--80" name={"frqcl30kg56"} layout={"l1"}>
          
          <ColumnWrap className="column__flex --left el--2" columns={"2"}>
            
            <ColumnWrapper >
              
              <Title className="title-box title-box--left" content={"Sledujte nás<br>"}>
              </Title>

              <Button className="btn-box btn-box--left" href={"https://www.facebook.com/pg/I-Horses-1287198564641287/community/?mt_nav=0&msite_tab_async=0"} content={"Facebook"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Title className="title-box" content={"Kontakt"}>
              </Title>

              <Text className="text-box" content={"I-Horses<br>Tehelná 26,Fiľakovo 986 01<br>+421 917 093 999<br>i-agro@i-agro.sk<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}